import React from "react"
import { graphql } from "gatsby"
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import EventList from "../../components/EventList"
import Events from "../../fixtures/workshops/all"
import LayoutNoStripe from "../../components/layoutNoStripe"
import SEO from "../../components/seo"
import {
  SectionHeading,
} from "../../styledComponents/section"
import {
  EventListSection
} from "../../styledComponents/eventList"
const Heading = ({ level = 1, location, children, offScreen}) => (
  <SectionHeading as={`h${level}`} level={level} location={location} offScreen={offScreen}>
    {children}
  </SectionHeading>
)

const EventsIndex = ({
  data,
  location,
  pageContext: { nextPagePath, previousPagePath, currentPage, numPages },
}) => {
  let allevents = Events
  if (!allevents.length) {
    return (
      <LayoutNoStripe location={location}>
        <SEO 
        title="Events and workshops in person and online related to energy healing, mindfulness, stress, healing and wellness."
        url="/events"
         />
        <p>
          No event posts found. Add events to your WordPress site and they'll
          appear here!
        </p>
      </LayoutNoStripe>
    )
  }

  return (
    <LayoutNoStripe location={location} displayNavBorder>
      <SEO 
        title="Energy Healing Events and Workshops"
        description="Live and online workshops and events related to energy healing, mindfulness, stress, healing, health and wellness"
        url="/events"
         />
      <EventListSection className="event-list">
        <Heading level={1} offScreen={true}>Upcoming workshops &amp; events</Heading>       
        <EventList workshops={allevents} eventType="all"/>
      </EventListSection>
    </LayoutNoStripe>
  )
}

export default EventsIndex

